import React from 'react';
import './style.css';
import logoKhan from 'assets/images/main-logo.svg';
import logoFacebook from 'assets/images/icon-facebook.svg';
import logoInstagram from 'assets/images/icon-instagram.svg';
import logoYoutube from 'assets/images/icon-youtube.svg';
import logoTikTok from "assets/images/icon-tiktok.svg";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();

  return (<div className = "footer-container">
            <div className = "footer-section contact">
              <div className = "contact-info-tittle">
                {t("footer.contactus")}
              </div>
              <div className = "contact-info">(+52) 47 7112 7560</div>
              <div className = "contact-info">kaananimation@gmail.com</div>
            </div>
            <div className = "separator">
            </div>
            <div className = "footer-section">
              <img src={logoKhan} alt="" className = "footer-logo"/>
              <b className = "studio-info">Copyright © KAAN Animation Studio 2021.</b>
              <b className = "studio-info">{t("footer.rights")}</b>
            </div>
            <div className = "separator">
            </div>
            <div className = "footer-section social">
              <div className = "social-footer-tittle">
                {t("footer.followus")}
              </div>
              <div className = "footer-logos-container">
                <a href="https://www.facebook.com/kaan.animstudio" target="_blank" rel="noreferrer">
                  <img src={logoFacebook} alt="" className = "footer-social-icon"/>
                </a>
                <a href="https://www.instagram.com/kaan_animation_studio/" target="_blank" rel="noreferrer">
                  <img src={logoInstagram} alt=""  className = "footer-social-icon"/>
                </a>
                <a href="https://www.youtube.com/channel/UCs-dbRa0T7pW44j2ZlTgjcw/" target="_blank" rel="noreferrer">
                  <img src={logoYoutube} alt="" className = "footer-social-icon"/>
                </a>
                <a href="https://www.tiktok.com/@kaan_animation" target="_blank" rel="noreferrer">
                  <img src={logoTikTok} alt="" className = "footer-social-icon"/>
                </a>
              </div>
            </div>
          </div>);
};

export default Footer;