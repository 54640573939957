import React, { useEffect, useState } from "react";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Home, About, Contact, Projects, Services } from "pages";
import { LanguageModal } from "components";
import { isLanguageSelected } from "helpers/language";

const App = () => {
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    if (!isLanguageSelected()) {
      setModalOpen(true);
    }
  }, []);

  return (
    <Router>
      <div>
        <LanguageModal
          isOpen={modalOpen}
          onClose={() => {
            setModalOpen(false);
          }}
        />
        <Switch>
          <Route path="/services">
            <Services />
          </Route>
          <Route path="/projects">
            <Projects />
          </Route>
          <Route path="/about">
            <About />
          </Route>
          <Route path="/contact">
            <Contact />
          </Route>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
      </div>
    </Router>
  );
};

export default App;
