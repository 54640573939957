import React, { useState } from "react";
import "./style.css";
import logoKhan from "assets/images/main-logo.svg";
import logoFacebook from "assets/images/icon-facebook.svg";
import logoInstagram from "assets/images/icon-instagram.svg";
import logoYoutube from "assets/images/icon-youtube.svg";
import logoTikTok from "assets/images/icon-tiktok.svg";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Suspense } from "react/cjs/react.production.min";
import { Languages } from "constants/laguages";
import { setLanguageSelected } from "helpers/language";

const Header = () => {
  const [navOpen, setNavOpen] = useState(false);
  const { i18n, t } = useTranslation();
  const onMenuClick = () => {
    setNavOpen(!navOpen);
  };

  const tootleText1 = i18n.language === Languages.ES ? "activated" : "disabled";
  const tootleText2 = i18n.language === Languages.EN ? "activated" : "disabled";

  const onToogleLanguage = () => {
    setLanguageSelected(
      i18n.language === Languages.ES ? Languages.EN : Languages.ES
    );
    i18n.changeLanguage(
      i18n.language === Languages.ES ? Languages.EN : Languages.ES
    );
  };

  return (
    <>
      <div className="header-container">
        <Link to="/" className="logo-wrapper">
          <img src={logoKhan} alt="kaan logo" className="header-logo-img" />
        </Link>
        <div className="sections-container">
          <Link to="/">
            <div className="sections-container-item">{t("header.home")}</div>
          </Link>
          <Link to="/services">
            <div className="sections-container-item">
              {t("header.services")}
            </div>
          </Link>
          <Link to="/projects">
            <div className="sections-container-item">
              {t("header.projects")}
            </div>
          </Link>
          <Link to="/about">
            <div className="sections-container-item">{t("header.aboutus")}</div>
          </Link>
          <Link to="/contact">
            <div className="sections-container-item">
              {t("header.contactus")}
            </div>
          </Link>
        </div>
        <div className="social-container">
          <a
            href="https://www.facebook.com/kaan.animstudio"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={logoFacebook}
              alt="facebook logo"
              className="header-social-logo"
            />
          </a>
          <a
            href="https://www.instagram.com/kaan_animation_studio/"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={logoInstagram}
              alt="instagram logo"
              className="header-social-logo"
            />
          </a>
          <a
            href="https://www.youtube.com/channel/UCs-dbRa0T7pW44j2ZlTgjcw/"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={logoYoutube}
              alt="youtube logo"
              className="header-social-logo"
            />
          </a>
          <a href="https://www.tiktok.com/@kaan_animation" target="_blank" rel="noreferrer">
            <img
              src={logoTikTok}
              alt="tiktok logo"
              className="header-social-logo"
            />
          </a>
          <div className="language-selector" onClick={onToogleLanguage}>
            <div className={tootleText1}>ES</div>
            <div className={tootleText2}>EN</div>
          </div>
        </div>
      </div>
      <div className="mobile-nav">
        <div className="mobile-nav-main">
          <div className="name">K&#39;aan Animations</div>
          <div onClick={onMenuClick} className="icon-container">
            <a href="javascript:void(0);" className="icon">
              <i className="fa fa-bars"></i>
            </a>
          </div>
        </div>
        {navOpen && (
          <div className="mobile-nav-container">
            <div className="mobile-nav-options">
              <Link to="/">
                <div className="single-option">{t("header.home")}</div>
              </Link>
              <Link to="/services">
                <div className="single-option">{t("header.services")}</div>
              </Link>
              <Link to="/projects">
                <div className="single-option">{t("header.projects")}</div>
              </Link>
              <Link to="/about">
                <div className="single-option">{t("header.aboutus")}</div>
              </Link>
              <Link to="/contact">
                <div className="single-option">{t("header.contactus")}</div>
              </Link>
            </div>
            <div className="mobile-nav-social">
              <a
                href="https://www.facebook.com/kaan.animstudio"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={logoFacebook}
                  alt="facebook logo"
                  className="header-social-logo"
                />
              </a>
              <a
                href="https://www.instagram.com/kaan_animation_studio/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={logoInstagram}
                  alt="instagram logo"
                  className="header-social-logo"
                />
              </a>
              <a
                href="https://www.youtube.com/channel/UCs-dbRa0T7pW44j2ZlTgjcw/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={logoYoutube}
                  alt="youtube logo"
                  className="header-social-logo"
                />
              </a>
              <a
                href="https://www.tiktok.com/@kaan_animation"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={logoTikTok}
                  alt="tiktok logo"
                  className="header-social-logo"
                />
              </a>
              <div className="language-selector" onClick={onToogleLanguage}>
                <div className={tootleText1}>ES</div>
                <div className={tootleText2}>EN</div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

const HeaderSuspense = () => {
  return (
    <Suspense fallback={() => <div>Loading</div>}>
      <Header />
    </Suspense>
  );
};

export default HeaderSuspense;
