import React, {useState, useEffect} from 'react';
import './style.css';
import { Header, Footer } from 'components';
import { useTranslation } from "react-i18next";
import { Player, BigPlayButton  } from 'video-react';
import './../../../node_modules/video-react/dist/video-react.css';
import ImgLeftArrow from 'assets/images/arrowLalternative.png';
import ImgRightArrow from 'assets/images/arrowRalternative.png';

import VideoReel from 'assets/videos/QuantumQuark_KAAN.mp4';
import ImgVideo from 'assets/images/side_vew.webp';
import VideoVFX from 'assets/videos/Chi_Montaje_V02.mp4';
import ImgVFXVideo from 'assets/images/imgVFX.webp';

import ImgSapiensPoster from 'assets/images/poster_V03.webp';
import ImgSapiensDino1 from 'assets/images/E04_S080664.webp';
import ImgSapiensDino2 from 'assets/images/E04_S030470.webp';
import ImgSapiensDino3 from 'assets/images/Sapienhoja16.webp';
import ImgQuatumQuarkPoster from 'assets/images/Previs_03.webp'; 
import ImgQuatumQuark1 from 'assets/images/qq_sinp27.webp';
import ImgQuatumQuark2 from 'assets/images/qq_conf25.webp';
import ImgQuatumQuark3 from 'assets/images/bar-concept.webp';
import LogoSapiens from 'assets/images/Logo_Sapiensaurus.webp';
import LogoQuantumQuark from 'assets/images/QuantumQuark_Color3D.webp';
import LogoPodcast from 'assets/images/podcast02.webp';
import ImgVFX1 from 'assets/images/FrayAntonio.webp';
import ImgVFX2 from 'assets/images/Hidalgo.webp';
import ImgVFX3 from 'assets/images/Wallpaper.webp';
import ImgSapiensComic1 from 'assets/images/Sapiensaurus_COVID19_01.webp';
import ImgSapiensComic2 from 'assets/images/Sapiensaurus_COVID19_02.webp';
import ImgSapiensComic3 from 'assets/images/Sapiensaurus_COVID19_03.webp';
import ImgQuatumQuarkGallery1 from 'assets/images/all-planets.webp';
import ImgQuatumQuarkGallery2 from 'assets/images/qq_planet05.webp';
import ImgQuatumQuarkGallery3 from 'assets/images/qq_planet12.webp';
import ImgQuatumQuarkGallery4 from 'assets/images/qq_planet15.webp';
import ImgQuatumQuarkGallery5 from 'assets/images/qq_planet16.webp';
import ImgQuatumQuarkGallery6 from 'assets/images/qq_planet17.webp';
import ImgQuatumQuarkGallery7 from 'assets/images/qq_planet20.webp';
import ImgQuatumQuarkGallery8 from 'assets/images/qq_planet21.webp';
import ImgQuatumQuarkGallery9 from 'assets/images/qq_planet22.webp';
import ImgQuatumQuarkGallery10 from 'assets/images/qq_planet23.webp';
import ImgQuatumQuarkGallery11 from 'assets/images/qq_planet24.webp';
import ImgQuatumQuarkGallery12 from 'assets/images/qq_planet26.webp';
import ImgQuatumQuarkGallery13 from 'assets/images/IZAR.webp';
import ImgQuatumQuarkGallery14 from 'assets/images/Kraz.webp';
import ImgQuatumQuarkGallery15 from 'assets/images/side_vew.webp';
import ImgQuatumQuarkGallery16 from 'assets/images/Previs_01.webp';
import ImgQuatumQuarkGallery17 from 'assets/images/Previs_02.webp';
import ImgQuatumQuarkGallery18 from 'assets/images/Description_side_vew.webp';
import ImgQuatumQuarkGallery19 from 'assets/images/evil-team-concept.webp';
import ImgPapirolas1 from 'assets/images/characters-3d-v11.webp';
import ImgPapirolas2 from 'assets/images/characters-3d-v12.webp';
import ImgPapirolas3 from 'assets/images/characters-3d-v13.webp';
import ImgEnvWarrior1 from 'assets/images/DWarrior_turn_V03_0028.webp';
import ImgEnvWarrior2 from 'assets/images/enviroment28.webp';
import ImgEnvWarrior3 from 'assets/images/enviroment27.webp';
import ImgEnvWarrior4 from 'assets/images/enviroment29.webp';

const Projects = function() {
  const { t } = useTranslation();
  useEffect(() => {
    if (window.AOS !== undefined) {
        window.AOS.init();
    }
  });

  const [activeQuantumGalleryElement, setActiveQuatumGalleryElement] = useState(0);
  const [activeGalleryElement, setActiveGalleryElement] = useState(0);

  return(<div>
    <Header />
    <div className='container-ip' id='IpW1'>
      <img src={LogoSapiens} className='container-ip-logo container-img-cover' data-aos="fade-down"/>
      <div className='container-ip-info'>
        <img src={ImgSapiensPoster} className='container-ip-info-img' data-aos="fade-right"/>
        <div className='container-ip-info-desc' data-aos="fade-left">
          <p className='container-ip-info-desc-text'>{t("home.ip-sapiensaurus-description")}</p>
        </div>
      </div>
      <div className='container-ip-gallery'>
        <img src={ImgSapiensDino1} className='container-ip-gallery-img' data-aos="fade-right"/>
        <img src={ImgSapiensDino2} className='container-ip-gallery-img' data-aos="fade-up"/>
        <img src={ImgSapiensDino3} className='container-ip-gallery-img-mb' data-aos="fade-left"/>
      </div>
      <div className='container-ip-gallery'>
        <img src={ImgSapiensPoster} className='container-ip-gallery-img-comic container-img-cover' data-aos="fade-left"/>
        <img src={ImgSapiensComic1} className='container-ip-gallery-img-comic' data-aos="fade-right"/>
        <img src={ImgSapiensComic2} className='container-ip-gallery-img-comic' data-aos="fade-left"/>
        <img src={ImgSapiensComic3} className='container-ip-gallery-img-comic' data-aos="fade-right"/>
      </div>
      <iframe className='container-ip-video' src="https://www.youtube.com/embed/1YtUF4xrZmY" title="K'aan Podcast" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
    </div>
    <div className='purple-separator-sm'></div>
    <div className='container-ip' id='IpW2'>
      <img src={LogoQuantumQuark} className='container-ip-logo' data-aos="fade-down"/>
      <div className='container-ip-info'>
        <div className='container-ip-info-desc' data-aos="fade-right">
          <p className='container-ip-info-desc-text'>{t("home.ip-quantum-description")}</p>
        </div>
        <img src={ImgQuatumQuarkPoster} className='container-ip-info-img' data-aos="fade-left"/>
      </div>
      <div className='container-ip-gallery'>
        <img src={ImgQuatumQuark1} className='container-ip-gallery-img' data-aos="fade-right"/>
        <img src={ImgQuatumQuark2} className='container-ip-gallery-img' data-aos="fade-up"/>
        <img src={ImgQuatumQuark3} className='container-ip-gallery-img-mb' data-aos="fade-left"/>
      </div>
      <div className='container-ip-gallery-extras'>
        <div className='container-ip-gallery-extras-arrow'>
          <img src={ImgLeftArrow} className='container-ip-gallery-extras-arrow-img' data-aos="fade-up"
          onClick = {() => setActiveQuatumGalleryElement(activeQuantumGalleryElement-1 < 0 ? 18 : activeQuantumGalleryElement-1)}/>
        </div>
        { 
          activeQuantumGalleryElement == 0 &&
          <div className='container-ip-gallery-extras-element'>
            <img src={ImgQuatumQuarkGallery1} className='container-ip-gallery-extras-element-img' data-aos="fade-up"/>
            <p className='container-ip-gallery-extras-element-desc'>Quatum Quark Gallery 1.</p>
          </div>
        }
        { 
          activeQuantumGalleryElement == 1 &&
          <div className='container-ip-gallery-extras-element'>
            <img src={ImgQuatumQuarkGallery2} className='container-ip-gallery-extras-element-img' data-aos="fade-up"/>
            <p className='container-ip-gallery-extras-element-desc'>Quatum Quark Gallery 2.</p>
          </div>
        }
        { 
          activeQuantumGalleryElement == 2 &&
          <div className='container-ip-gallery-extras-element'>
            <img src={ImgQuatumQuarkGallery3} className='container-ip-gallery-extras-element-img' data-aos="fade-up"/>
            <p className='container-ip-gallery-extras-element-desc'>Quatum Quark Gallery 3.</p>
          </div>
        }
        { 
          activeQuantumGalleryElement == 3 &&
          <div className='container-ip-gallery-extras-element'>
            <img src={ImgQuatumQuarkGallery4} className='container-ip-gallery-extras-element-img' data-aos="fade-up"/>
            <p className='container-ip-gallery-extras-element-desc'>Quatum Quark Gallery 4.</p>
          </div>
        }
        { 
          activeQuantumGalleryElement == 4 &&
          <div className='container-ip-gallery-extras-element'>
            <img src={ImgQuatumQuarkGallery5} className='container-ip-gallery-extras-element-img' data-aos="fade-up"/>
            <p className='container-ip-gallery-extras-element-desc'>Quatum Quark Gallery 5.</p>
          </div>
        }
        { 
          activeQuantumGalleryElement == 5 &&
          <div className='container-ip-gallery-extras-element'>
            <img src={ImgQuatumQuarkGallery6} className='container-ip-gallery-extras-element-img' data-aos="fade-up"/>
            <p className='container-ip-gallery-extras-element-desc'>Quatum Quark Gallery 6.</p>
          </div>
        }
        { 
          activeQuantumGalleryElement == 6 &&
          <div className='container-ip-gallery-extras-element'>
            <img src={ImgQuatumQuarkGallery7} className='container-ip-gallery-extras-element-img' data-aos="fade-up"/>
            <p className='container-ip-gallery-extras-element-desc'>Quatum Quark Gallery 7.</p>
          </div>
        }
        { 
          activeQuantumGalleryElement == 7 &&
          <div className='container-ip-gallery-extras-element'>
            <img src={ImgQuatumQuarkGallery8} className='container-ip-gallery-extras-element-img' data-aos="fade-up"/>
            <p className='container-ip-gallery-extras-element-desc'>Quatum Quark Gallery 8.</p>
          </div>
        }
        { 
          activeQuantumGalleryElement == 8 &&
          <div className='container-ip-gallery-extras-element'>
            <img src={ImgQuatumQuarkGallery9} className='container-ip-gallery-extras-element-img' data-aos="fade-up"/>
            <p className='container-ip-gallery-extras-element-desc'>Quatum Quark Gallery 9.</p>
          </div>
        }
        { 
          activeQuantumGalleryElement == 9 &&
          <div className='container-ip-gallery-extras-element'>
            <img src={ImgQuatumQuarkGallery10} className='container-ip-gallery-extras-element-img' data-aos="fade-up"/>
            <p className='container-ip-gallery-extras-element-desc'>Quatum Quark Gallery 10.</p>
          </div>
        }
        { 
          activeQuantumGalleryElement == 10 &&
          <div className='container-ip-gallery-extras-element'>
            <img src={ImgQuatumQuarkGallery11} className='container-ip-gallery-extras-element-img' data-aos="fade-up"/>
            <p className='container-ip-gallery-extras-element-desc'>Quatum Quark Gallery 11.</p>
          </div>
        }
        { 
          activeQuantumGalleryElement == 11 &&
          <div className='container-ip-gallery-extras-element'>
            <img src={ImgQuatumQuarkGallery12} className='container-ip-gallery-extras-element-img' data-aos="fade-up"/>
            <p className='container-ip-gallery-extras-element-desc'>Quatum Quark Gallery 12.</p>
          </div>
        }
        { 
          activeQuantumGalleryElement == 12 &&
          <div className='container-ip-gallery-extras-element'>
            <img src={ImgQuatumQuarkGallery13} className='container-ip-gallery-extras-element-img' data-aos="fade-up"/>
            <p className='container-ip-gallery-extras-element-desc'>Quatum Quark Gallery 13.</p>
          </div>
        }
        { 
          activeQuantumGalleryElement == 13 &&
          <div className='container-ip-gallery-extras-element'>
            <img src={ImgQuatumQuarkGallery14} className='container-ip-gallery-extras-element-img' data-aos="fade-up"/>
            <p className='container-ip-gallery-extras-element-desc'>Quatum Quark Gallery 14.</p>
          </div>
        }
        { 
          activeQuantumGalleryElement == 14 &&
          <div className='container-ip-gallery-extras-element'>
            <img src={ImgQuatumQuarkGallery15} className='container-ip-gallery-extras-element-img' data-aos="fade-up"/>
            <p className='container-ip-gallery-extras-element-desc'>Quatum Quark Gallery 15.</p>
          </div>
        }
        { 
          activeQuantumGalleryElement == 15 &&
          <div className='container-ip-gallery-extras-element'>
            <img src={ImgQuatumQuarkGallery16} className='container-ip-gallery-extras-element-img' data-aos="fade-up"/>
            <p className='container-ip-gallery-extras-element-desc'>Quatum Quark Gallery 16.</p>
          </div>
        }
        { 
          activeQuantumGalleryElement == 16 &&
          <div className='container-ip-gallery-extras-element'>
            <img src={ImgQuatumQuarkGallery17} className='container-ip-gallery-extras-element-img' data-aos="fade-up"/>
            <p className='container-ip-gallery-extras-element-desc'>Quatum Quark Gallery 17.</p>
          </div>
        }
        { 
          activeQuantumGalleryElement == 17 &&
          <div className='container-ip-gallery-extras-element'>
            <img src={ImgQuatumQuarkGallery18} className='container-ip-gallery-extras-element-img' data-aos="fade-up"/>
            <p className='container-ip-gallery-extras-element-desc'>Quatum Quark Gallery 18.</p>
          </div>
        }
        { 
          activeQuantumGalleryElement == 18 &&
          <div className='container-ip-gallery-extras-element'>
            <img src={ImgQuatumQuarkGallery19} className='container-ip-gallery-extras-element-img' data-aos="fade-up"/>
            <p className='container-ip-gallery-extras-element-desc'>Quatum Quark Gallery 19.</p>
          </div>
        }
        <div className='container-ip-gallery-extras-arrow'>
          <img src={ImgRightArrow} className='container-ip-gallery-extras-arrow-img' data-aos="fade-up"
          onClick = {() => setActiveQuatumGalleryElement((activeQuantumGalleryElement+1)%19)}/>
        </div>
      </div>
      <div className='container-ip-gallery'>
        <Player
          playsInline
          poster={ImgVideo}
          src={VideoReel}
          fluid={false}
          width={screen.availWidth*0.75}
          height={screen.availHeight*0.55}
          aspectRatio={'auto'}
        >
          <BigPlayButton position="center" />
        </Player>
      </div>
    </div>
    <div className='purple-separator-sm'></div>
    <div className='container-ip' id='IpW3'>
      <img src={LogoPodcast} className='container-ip-logo' data-aos="fade-down"/>
      <div className='container-ip-info'>
        <div className='container-ip-info-desc-youtube' data-aos="fade-up">
          <p className='container-ip-info-desc-text'>{t("home.ip-youtube-description")}</p>
        </div>
      </div>
      <div className='container-ip-videos'>
        <iframe className='container-ip-video' src="https://www.youtube-nocookie.com/embed/JZD2WwUXOt8" title="K'aan Podcast" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        <iframe className='container-ip-video' src="https://www.youtube-nocookie.com/embed/UFp1f9aT_Ws" title="K'aan Podcast" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
      </div>
    </div>
    <div className='purple-separator-sm'></div>
    <div className='container-ip' id='IpW4'>
      <div className='container-ip-info-desc-youtube' data-aos="fade-up">
        <p className='container-ip-info-desc-title'>{t("home.ip-gallery")}</p>
      </div>
      <div className='container-ip-gallery-extras'>
        <div className='container-ip-gallery-extras-arrow'>
          <img src={ImgLeftArrow} className='container-ip-gallery-extras-arrow-img' data-aos="fade-up"
          onClick = {() => setActiveGalleryElement(activeGalleryElement-1 < 0 ? 10 : activeGalleryElement-1)}/>
        </div>
        { 
          activeGalleryElement == 0 &&
          <Player
            playsInline
            poster={ImgVFXVideo}
            src={VideoVFX}
            fluid={false}
            width={screen.availWidth*0.75}
            height={screen.availHeight*0.75}
            aspectRatio={'auto'}
          >
            <BigPlayButton position="center" />
          </Player>
        }
        { 
          activeGalleryElement == 1 &&
          <div className='container-ip-gallery-extras-element'>
            <img src={ImgVFX1} className='container-ip-gallery-extras-element-img' data-aos="fade-up"/>
            <p className='container-ip-gallery-extras-element-desc'>VFX 1.</p>
          </div>
        }
        { 
          activeGalleryElement == 2 &&
          <div className='container-ip-gallery-extras-element'>
            <img src={ImgVFX2} className='container-ip-gallery-extras-element-img' data-aos="fade-up"/>
            <p className='container-ip-gallery-extras-element-desc'>VFX 2.</p>
          </div>
        }
        { 
          activeGalleryElement == 3 &&
          <div className='container-ip-gallery-extras-element'>
            <img src={ImgVFX3} className='container-ip-gallery-extras-element-img' data-aos="fade-up"/>
            <p className='container-ip-gallery-extras-element-desc'>VFX 3.</p>
          </div>
        }
        { 
          activeGalleryElement == 4 &&
          <div className='container-ip-gallery-extras-element'>
            <img src={ImgPapirolas1} className='container-ip-gallery-extras-element-img' data-aos="fade-up"/>
            <p className='container-ip-gallery-extras-element-desc'>Papirolas 1.</p>
          </div>
        }
        { 
          activeGalleryElement == 5 &&
          <div className='container-ip-gallery-extras-element'>
            <img src={ImgPapirolas2} className='container-ip-gallery-extras-element-img' data-aos="fade-up"/>
            <p className='container-ip-gallery-extras-element-desc'>Papirolas 2.</p>
          </div>
        }
        { 
          activeGalleryElement == 6 &&
          <div className='container-ip-gallery-extras-element'>
            <img src={ImgPapirolas3} className='container-ip-gallery-extras-element-img' data-aos="fade-up"/>
            <p className='container-ip-gallery-extras-element-desc'>Papirolas 3.</p>
          </div>
        }
        { 
          activeGalleryElement == 7 &&
          <div className='container-ip-gallery-extras-element'>
            <img src={ImgEnvWarrior1} className='container-ip-gallery-extras-element-img' data-aos="fade-up"/>
            <p className='container-ip-gallery-extras-element-desc'>Templo 1.</p>
          </div>
        }
        { 
          activeGalleryElement == 8 &&
          <div className='container-ip-gallery-extras-element'>
            <img src={ImgEnvWarrior2} className='container-ip-gallery-extras-element-img' data-aos="fade-up"/>
            <p className='container-ip-gallery-extras-element-desc'>Templo 2.</p>
          </div>
        }
        { 
          activeGalleryElement == 9 &&
          <div className='container-ip-gallery-extras-element'>
            <img src={ImgEnvWarrior3} className='container-ip-gallery-extras-element-img' data-aos="fade-up"/>
            <p className='container-ip-gallery-extras-element-desc'>Templo 3.</p>
          </div>
        }
        { 
          activeGalleryElement == 10 &&
          <div className='container-ip-gallery-extras-element'>
            <img src={ImgEnvWarrior4} className='container-ip-gallery-extras-element-img' data-aos="fade-up"/>
            <p className='container-ip-gallery-extras-element-desc'>Templo 4.</p>
          </div>
        }
        <div className='container-ip-gallery-extras-arrow'>
          <img src={ImgRightArrow} className='container-ip-gallery-extras-arrow-img' data-aos="fade-up"
          onClick = {() => setActiveGalleryElement((activeGalleryElement+1)%11)}/>
        </div>
      </div>
    </div>
    <Footer />
  </div>);
};

export default Projects;