import React, {useEffect} from 'react';
import './style.css';
import { Header, Footer } from 'components';
import { useTranslation } from "react-i18next";
import { Player, BigPlayButton  } from 'video-react';
import './../../../node_modules/video-react/dist/video-react.css';
import VideoReel from 'assets/videos/reel.mp4';
import ImgVideo from 'assets/images/intro_V02_00089.webp';
import LogoAura from 'assets/images/logo_aura.webp';
import LogoCaos from 'assets/images/logo_caos.webp';
import LogoDeep from 'assets/images/LOGO_DEEPIXEL.webp';
import LogoEregebe from 'assets/images/logo_eregebe.png';
import LogoIlimita from 'assets/images/LOGO_ILIMITA_BLANCO.webp';
import LogoPlatypus from 'assets/images/logo_platypus.webp';
import LogoCebolla from 'assets/images/CEBOLLA.webp';
import LogoHeski from "assets/images/Heskil_Logo_V2_Metal.webp";
import LogoDX from "assets/images/dxlogo_69.webp";

const About = function() {
  const { t } = useTranslation();
  useEffect(() => {
    if (window.AOS !== undefined) {
        window.AOS.init();
    }
  });
  
  return(<div className='about-container'>
    <Header />
    <div className='container-reel'>
      <Player
            playsInline
            poster={ImgVideo}
            src={VideoReel}
            fluid={false}
            width={screen.availWidth*0.8}
            height={screen.availHeight*0.8}
            aspectRatio={'auto'}
        >
            <BigPlayButton position="center" />
        </Player>
    </div>
    <div className='container-description'>
      <p className='container-description-text' data-aos="fade-up">{t("home.intro1")}</p>
      <p className='container-description-text' data-aos="fade-up">{t("home.intro2")}</p>
      <p className='container-description-text' data-aos="fade-up">{t("home.intro3")}</p>
    </div>
    <div className='container-missviss'>
      <div className='container-missviss-mission' data-aos="flip-up">
        <h2 className='container-missviss-mission-title'>{t("aboutus.mission")}</h2>
        <p className='container-missviss-mission-text'>{t("aboutus.mission-description-1")}</p>
        <p className='container-missviss-mission-text'>{t("aboutus.mission-description-2")}</p>
      </div>
      <div className='container-missviss-vission' data-aos="flip-up">
        <h2 className='container-missviss-mission-title'>{t("aboutus.vision")}</h2>
        <p className='container-missviss-mission-text'>{t("aboutus.vision-description-1")}</p>
        <p className='container-missviss-mission-text'>{t("aboutus.vision-description-2")}</p>
      </div>
    </div>
    <div className='white-separator-sm'></div>
    <div className='container-collaborators'>
      <h2 className='container-collaborators-title' data-aos="fade-up">{t("home.collaborators-tile")}</h2>
      <div className='container-collaborators-row'>
        <img src={LogoAura} className='container-collaborators-row-img' data-aos="zoom-in-up"/>
        <img src={LogoCaos} className='container-collaborators-row-img' data-aos="zoom-in-up"/>
        <img src={LogoDeep} className='container-collaborators-row-img' data-aos="zoom-in-up"/>
        <img src={LogoEregebe} className='container-collaborators-row-img' data-aos="zoom-in-up"/>
        <img src={LogoIlimita} className='container-collaborators-row-img' data-aos="zoom-in-up"/>
        <img src={LogoPlatypus} className='container-collaborators-row-img' data-aos="zoom-in-up"/>
        <img src={LogoCebolla} className='container-collaborators-row-img' data-aos="zoom-in-up"/>
        <img src={LogoHeski} className='container-collaborators-row-img' data-aos="zoom-in-up"/>
        <img src={LogoDX} className='container-collaborators-row-img' data-aos="zoom-in-up"/>
      </div>
    </div>
    <Footer />
  </div>);
};

export default About;