const LANG_KEY = "SELECTED_LANGUAGE";

const isLanguageSelected = () => {
  const selectedLanguage = localStorage.getItem(LANG_KEY);
  return !!selectedLanguage;
};

const setLanguageSelected = (lang) => {
  localStorage.setItem(LANG_KEY, lang);
};

export { isLanguageSelected, setLanguageSelected };
