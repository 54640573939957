import React, { useEffect, Suspense } from "react";
import "./style.css";
import { useTranslation } from "react-i18next";
import { Header, Footer } from "components";
import ImgWarrior from "assets/images/guerrero.webp";
import IconAppAR from "assets/images/ariconwhite.png";
import IconAppVR from "assets/images/vriconwhite.png";
import IconV360 from "assets/images/360-iconwhite.png";
import ImgVR from "assets/images/pexels-vr.webp";
import ImgAR from "assets/images/pexels-ar.webp";
import ImgWarrior360 from "assets/images/enviroment29.webp";
import ImgWarriorEsc from "assets/images/warrior16.webp";
import ImgMedusaMod from "assets/images/medusa6.webp";
import ImgMaskText from "assets/images/mask01.webp";
import ImgDinoRig from "assets/images/E04_S080664.webp";
import ImgMotionGrap from "assets/images/FrayAntonio.webp";
import ImgVFX from "assets/images/imgVFX.webp";

const ServicesContent = function () {
  const { t } = useTranslation();
  useEffect(() => {
    if (window.AOS !== undefined) {
      window.AOS.init();
    }
  });

  return (
    <div className="services-container">
      <Header />
      <div className="container-presentation-tec">
        <h1 className="container-presentation-tec-title" data-aos="zoom-out">
          {t("services.title")}
        </h1>
        <p className="container-presentation-tec-text" data-aos="zoom-out">
          {t("services.subdescription")}
        </p>
      </div>
      <div className="container-services-tec">
        <div className="container-services-tec-row" id="AppsAR">
          <div
            className="container-services-tec-row-info"
            data-aos="flip-right"
          >
            <img
              src={IconAppAR}
              className="container-services-tec-row-info-icon"
            />
            <h3 className="container-services-tec-row-info-title">
              {t("home.ar-apps")}
            </h3>
            <p className="container-services-tec-row-info-text">
              {t("home.ar-apps-description-1")}
            </p>
            <p className="container-services-tec-row-info-text">
              {t("home.ar-apps-description-2")}
            </p>
          </div>
          <img
            src={ImgAR}
            className="container-services-tec-row-img"
            data-aos="flip-left"
          />
          <img
            src={ImgAR}
            className="container-services-tec-row-img-mb"
            data-aos="flip-left"
          />
        </div>
        <div className="container-services-tec-row" id="AppsVR">
          <img
            src={ImgVR}
            className="container-services-tec-row-img"
            data-aos="flip-right"
          />
          <div className="container-services-tec-row-info" data-aos="flip-left">
            <img
              src={IconAppVR}
              className="container-services-tec-row-info-icon"
            />
            <h3 className="container-services-tec-row-info-title">
              {t("home.vr-apps")}
            </h3>
            <p className="container-services-tec-row-info-text">
              {t("home.vr-apps-description-1")}
            </p>
            <p className="container-services-tec-row-info-text">
              {t("home.vr-apps-description-2")}
            </p>
          </div>
          <img
            src={ImgVR}
            className="container-services-tec-row-img-mb"
            data-aos="flip-right"
          />
        </div>
        <div className="container-services-tec-row" id="Apps360">
          <div
            className="container-services-tec-row-info"
            data-aos="flip-right"
          >
            <img
              src={IconV360}
              className="container-services-tec-row-info-icon"
            />
            <h3 className="container-services-tec-row-info-title">
              {t("home.360-videos")}
            </h3>
            <p className="container-services-tec-row-info-text">
              {t("home.360-videos-description")}
            </p>
          </div>
          <img
            src={ImgWarrior360}
            className="container-services-tec-row-img"
            data-aos="flip-left"
          />
          <img
            src={ImgWarrior360}
            className="container-services-tec-row-img-mb"
            data-aos="flip-left"
          />
        </div>
      </div>
      <div className="container-presentation-adv">
        <h1 className="container-presentation-adv-title" data-aos="zoom-out">
          {t("home.audiovisual-services")}
        </h1>
        <p className="container-presentation-adv-text" data-aos="zoom-out">
          {t("services.audiovisual-services-description-1")}
        </p>
        <p className="container-presentation-adv-text" data-aos="zoom-out">
          {t("services.audiovisual-services-description-2")}
        </p>
      </div>
      <div className="container-services-adv">
        <div className="container-services-adv-row" id="ServiceM3D">
          <img
            src={ImgMedusaMod}
            className="container-services-adv-row-img"
            data-aos="zoom-out-left"
          />
          <div
            className="container-services-adv-row-info"
            data-aos="zoom-out-right"
          >
            <h3 className="container-services-adv-row-info-title">
              {t("home.3D-modeling")}
            </h3>
            <p className="container-services-adv-row-info-text">
              {t("home.3D-modeling-description-1")}
            </p>
            <p className="container-services-adv-row-info-text">
              {t("home.3D-modeling-description-2")}
            </p>
          </div>
          <img
            src={ImgMedusaMod}
            className="container-services-adv-row-img-mb"
            data-aos="zoom-out-right"
          />
        </div>
        <div className="container-services-adv-row" id="ServiceED">
          <div
            className="container-services-adv-row-info"
            data-aos="zoom-out-left"
          >
            <h3 className="container-services-adv-row-info-title">
              {t("home.digital-sculpture")}
            </h3>
            <p className="container-services-adv-row-info-text">
              {t("home.digital-sculpture-description")}
            </p>
          </div>
          <img
            src={ImgWarriorEsc}
            className="container-services-adv-row-img"
            data-aos="zoom-out-right"
          />
          <img
            src={ImgWarriorEsc}
            className="container-services-adv-row-img-mb"
            data-aos="zoom-out-left"
          />
        </div>
        <div className="container-services-adv-row" id="ServiceTextt">
          <img
            src={ImgMaskText}
            className="container-services-adv-row-img"
            data-aos="zoom-out-left"
          />
          <div
            className="container-services-adv-row-info"
            data-aos="zoom-out-right"
          >
            <h3 className="container-services-adv-row-info-title">
              {t("home.texturing")}
            </h3>
            <p className="container-services-adv-row-info-text">
              {t("home.texturing-description")}
            </p>
          </div>
          <img
            src={ImgMaskText}
            className="container-services-adv-row-img-mb"
            data-aos="zoom-out-right"
          />
        </div>
        <div className="container-services-adv-row" id="ServiceRigs">
          <div
            className="container-services-adv-row-info"
            data-aos="zoom-out-left"
          >
            <h3 className="container-services-adv-row-info-title">
              {t("home.rig-2d3d")}
            </h3>
            <p className="container-services-adv-row-info-text">
              {t("home.rig-2d3d-description")}
            </p>
          </div>
          <img
            src={ImgDinoRig}
            className="container-services-adv-row-img"
            data-aos="zoom-out-right"
          />
          <img
            src={ImgDinoRig}
            className="container-services-adv-row-img-mb"
            data-aos="zoom-out-left"
          />
        </div>
        <div className="container-services-adv-row" id="ServiceAnim">
          <img
            src={ImgWarrior}
            className="container-services-adv-row-img"
            data-aos="zoom-out-left"
          />
          <div
            className="container-services-adv-row-info"
            data-aos="zoom-out-right"
          >
            <h3 className="container-services-adv-row-info-title">
              {t("home.animations")}
            </h3>
            <p className="container-services-adv-row-info-text">
              {t("home.animations-description")}
            </p>
          </div>
          <img
            src={ImgWarrior}
            className="container-services-adv-row-img-mb"
            data-aos="zoom-out-right"
          />
        </div>
        <div className="container-services-adv-row" id="ServiceMG">
          <div
            className="container-services-adv-row-info"
            data-aos="zoom-out-left"
          >
            <h3 className="container-services-adv-row-info-title">
              {t("home.motion-graphics")}
            </h3>
            <p className="container-services-adv-row-info-text">
              {t("home.motion-graphics-description-1")}
            </p>
            <p className="container-services-adv-row-info-text">
              {t("home.motion-graphics-description-2")}
            </p>
          </div>
          <img
            src={ImgMotionGrap}
            className="container-services-adv-row-img"
            data-aos="zoom-out-right"
          />
          <img
            src={ImgMotionGrap}
            className="container-services-adv-row-img-mb"
            data-aos="zoom-out-left"
          />
        </div>
        <div className="container-services-adv-row" id="ServiceCVFX">
          <img
            src={ImgVFX}
            className="container-services-adv-row-img"
            data-aos="zoom-out-left"
          />
          <div
            className="container-services-adv-row-info"
            data-aos="zoom-out-right"
          >
            <h3 className="container-services-adv-row-info-title">
              Compositing/VFX
              {t("home.compositing")}
            </h3>
            <p className="container-services-adv-row-info-text">
              {t("home.compositing-description-1")}
            </p>
            <p className="container-services-adv-row-info-text">
              {t("home.compositing-description-2")}
            </p>
          </div>
          <img
            src={ImgVFX}
            className="container-services-adv-row-img-mb"
            data-aos="zoom-out-right"
          />
        </div>
      </div>
      <Footer />
    </div>
  );
};

const Services = () => {
  return (
    <Suspense fallback={() => <div>Loading</div>}>
      <ServicesContent />
    </Suspense>
  );
};

export default Services;
