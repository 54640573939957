import React, {useEffect} from 'react';
import './style.css';
import { Header, Footer } from 'components';
import { useTranslation } from "react-i18next";
import IconFacebook from 'assets/images/icon-facebook.svg';
import IconInstagram from 'assets/images/icon-instagram.svg';
import IconYoutube from 'assets/images/icon-youtube.svg';
import IconTikTok from "assets/images/icon-tiktok.svg";

const Contact = function() {
  const { t } = useTranslation();
  useEffect(() => {
    if (window.AOS !== undefined) {
        window.AOS.init();
    }
  });

  return(<div className='contact-container'>
    <Header />
    <div className='container-banner'>
      <h1 className='container-banner-msg' data-aos="zoom-in-up">{t("contactus.title")}</h1>
    </div>
    <div className='container-contact'>
      <div className='container-contact-form' data-aos="zoom-in-right">
        <h2 className='container-contact-form-title'>{t("contactus.writeus")}</h2>
        <div className='container-contact-form-data'> 
          <input type="text" placeholder={t("contactus.name")} className='container-contact-form-data-input' name="contact_name"/>
          <input type="text" placeholder={t("contactus.email")} className='container-contact-form-data-input' name="email"/>
          <input type="text" placeholder={t("contactus.subject")} className='container-contact-form-data-input' name="interest"/>
          <textarea rows="3" cols="50"  placeholder={t("contactus.message")} className="container-contact-form-data-area" name="msg"/>
        </div>
        <button className="container-contact-form-button">
          {t("contactus.send")}
        </button>
      </div>
      <div className='container-info-details' data-aos="zoom-in-left">
        <h2 className='container-info-details-title'>{t("contactus.contactus")}</h2>
        <p className='container-info-details-text'>kaananimation@gmail.com</p>
        <p className='container-info-details-text'>(+52) 47 7112 7560</p>
        <div className='container-info-details-links'>
          <a href="https://www.facebook.com/kaan.animstudio" target="_blank" rel="noreferrer">
            <img src={IconFacebook} className='container-info-details-links-social'/>
          </a>
          <a href="https://www.instagram.com/kaan_animation_studio/" target="_blank" rel="noreferrer">
            <img src={IconInstagram} className='container-info-details-links-social'/>
          </a>
          <a href="https://www.youtube.com/channel/UCs-dbRa0T7pW44j2ZlTgjcw/" target="_blank" rel="noreferrer">
            <img src={IconYoutube} className='container-info-details-links-social'/>
          </a>
          <a href="https://www.tiktok.com/@kaan_animation" target="_blank" rel="noreferrer">
            <img src={IconTikTok} className='container-info-details-links-social'/>
          </a>
        </div>
      </div>
    </div>
    <Footer />
  </div>);
};

export default Contact;