import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { setLanguageSelected } from "helpers/language";
import { Languages } from "constants/laguages";

import "./styles.css";

const LanguageModal = ({ isOpen, onClose }) => {
  const { i18n } = useTranslation();
  const [selectedLang, setSelectedLang] = useState(Languages.ES);
  const handleLanguageChange = (e) => {
    setSelectedLang(e.currentTarget.value);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setLanguageSelected(selectedLang);
    i18n.changeLanguage(selectedLang);
    onClose();
  };
  if (!isOpen) {
    return null;
  }
  return (
    <div className="modal-wrapper">
      <div className="modal-content">
        <h4 className="modal-title">Por favor, selecciona tu idioma / Please, choose your language</h4>
        <form onSubmit={handleSubmit} className="modal-form">
          <label htmlFor="spanish_rad" className="form-label">
            <input
              type="radio"
              name="language"
              value={Languages.ES}
              id="spanish_rad"
              checked={selectedLang === Languages.ES}
              onChange={handleLanguageChange}
            />
            Español
          </label>

          <label htmlFor="english_rad" className="form-label">
            <input
              type="radio"
              name="language"
              value={Languages.EN}
              id="english_rad"
              checked={selectedLang === Languages.EN}
              onChange={handleLanguageChange}
            />
            English
          </label>

          <button className="modal-button">
            {selectedLang === Languages.ES ? "Aceptar" : "Accept"}
          </button>
        </form>
      </div>
    </div>
  );
};

LanguageModal.propTypes = {
  isOpen: Boolean,
  onClose: Function,
};

export default LanguageModal;
