import React, { useState, useEffect } from "react";
import "./style.css";
import { Header, Footer } from "components";
import { Player, BigPlayButton } from "video-react";
import "./../../../node_modules/video-react/dist/video-react.css";
import { useTranslation } from "react-i18next";
import VideoReel from "assets/videos/reel.mp4";
import ImgVideo from "assets/images/intro_V02_00089.webp";
import ImgWarrior from "assets/images/guerrero.webp";
import IconAppAR from "assets/images/ariconwhite.png";
import IconAppVR from "assets/images/vriconwhite.svg";
import IconV360 from "assets/images/360-iconwhite.png";

import ImgVR from "assets/images/pexels-vr.webp";
import ImgAR from "assets/images/pexels-ar.webp";
import ImgWarrior360 from "assets/images/enviroment29.webp";
import ImgWarriorEsc from "assets/images/DWarrior_turn_V03_0028.webp";
import ImgMedusaMod from "assets/images/medusa6.webp";
import ImgMaskText from "assets/images/mask01.webp";
import ImgDinoRig from "assets/images/E04_S080664.webp";
import ImgMotionGrap from "assets/images/FrayAntonio.webp";
import ImgVFX from "assets/images/imgVFX.webp";
import ImgSapiensDino from "assets/images/poster_V03.webp";
import ImgYoutubeChannel from "assets/images/podcast02.webp";
import ImgQuantumQuarck from "assets/images/QuantumQuark_Color3D_V04.webp";
import ImgGalery from "assets/images/enviroment28.webp";

import LogoAura from "assets/images/logo_aura.webp";
import LogoCaos from "assets/images/logo_caos.webp";
import LogoDeep from "assets/images/LOGO_DEEPIXEL.webp";
import LogoEregebe from "assets/images/logo_eregebe.png";
import LogoIlimita from "assets/images/LOGO_ILIMITA_BLANCO.webp";
import LogoPlatypus from "assets/images/logo_platypus.webp";
import LogoCebolla from "assets/images/CEBOLLA.webp";
import LogoHeski from "assets/images/Heskil_Logo_V2_Metal.webp";
import LogoDX from "assets/images/dxlogo_69.webp";

const ServTecCollection = {
  AR: "ar",
  VR: "vr",
  V3D: "v3d",
};

const ServAdvCollection = {
  M3D: "Modelado3D",
  ED: "EsculturaDigital",
  TEXTT: "Texturizado",
  RIGS: "Rigs2D/3D",
  ANIM: "Animaciones2D/3D",
  MG: "MotionGraphics2D/3D",
  CVFX: "Compositing/VFX",
};

const WorksCollection = {
  W1: "Work1",
  W2: "Work2",
  W3: "Work3",
  W4: "Work4",
};

const Home = function () {
  const { t } = useTranslation();
  useEffect(() => {
    if (window.AOS !== undefined) {
      window.AOS.init();
    }
  });

  const [activeSerTec, setactiveSerTec] = useState(ServTecCollection.AR);
  const [activeSerAdv, setactiveAdvTec] = useState(ServAdvCollection.M3D);
  const [activeWork, setactiveWork] = useState(WorksCollection.W1);

  return (
    <div className="main-container">
      <Header />
      <div className="section-home">
        <Player
          playsInline
          poster={ImgVideo}
          src={VideoReel}
          fluid={false}
          width={screen.availWidth * 0.95}
          height={screen.availHeight * 0.95}
          aspectRatio={"auto"}
        >
          <BigPlayButton position="center" />
        </Player>
      </div>
      <div className="section-about-us">
        <b className="section-about-us-text" data-aos="fade-up">
          <p>{t("services.title")}</p>
          <p>{t("home.intro1")}</p>
        </b>
        <p className="section-about-us-text" data-aos="fade-up">
          {t("home.intro2")}
        </p>
        <p className="section-about-us-text" data-aos="fade-up">
          {t("home.intro3")}
        </p>
      </div>
      <div className="yellow-separator"></div>
      <div className="section-services-tec">
        <h2 className="section-services-tec-title" data-aos="flip-up">
          {t("home.techServices")}
        </h2>
        <div className="section-services-tec-opt" data-aos="flip-up">
          <div
            className={`section-services-tec-opt-element ${
              activeSerTec === ServTecCollection.AR ? "active" : ""
            }`}
            onClick={() => setactiveSerTec(ServTecCollection.AR)}
          >
            <img
              src={IconAppAR}
              className="section-services-tec-opt-element-icon"
            />
            <span className="section-services-tec-opt-element-title">
              {t("home.ar-apps")}
            </span>
            <div className="arrow-up"></div>
          </div>
          <div
            className={`section-services-tec-opt-element ${
              activeSerTec === ServTecCollection.VR ? "active" : ""
            }`}
            onClick={() => setactiveSerTec(ServTecCollection.VR)}
          >
            <img
              src={IconAppVR}
              className="section-services-tec-opt-element-icon"
            />
            <span className="section-services-tec-opt-element-title">
              {t("home.vr-apps")}
            </span>
            <div className="arrow-up"></div>
          </div>
          <div
            className={`section-services-tec-opt-element ${
              activeSerTec === ServTecCollection.V3D ? "active" : ""
            }`}
            onClick={() => setactiveSerTec(ServTecCollection.V3D)}
          >
            <img
              src={IconV360}
              className="section-services-tec-opt-element-icon"
            />
            <span className="section-services-tec-opt-element-title">
              {t("home.360-videos")}
            </span>
            <div className="arrow-up"></div>
          </div>
        </div>
        {activeSerTec === ServTecCollection.AR && (
          <div className="section-services-tec-info" data-aos="flip-left">
            <img src={ImgAR} className="section-services-tec-info-img" />
            <div className="section-services-tec-info-desc">
              <p className="section-services-tec-info-desc-text">
                {t("home.ar-apps-description-1")}
              </p>
              <p className="section-services-tec-info-desc-text">
                {t("home.ar-apps-description-2")}
              </p>
              <a
                href="/services#AppsAR"
                className="section-services-tec-desc-link"
              >
                {t("common.view-more")}
              </a>
            </div>
          </div>
        )}
        {activeSerTec === ServTecCollection.VR && (
          <div className="section-services-tec-info" data-aos="flip-up">
            <img src={ImgVR} className="section-services-tec-info-img" />
            <div className="section-services-tec-info-desc">
              <p className="section-services-tec-info-desc-text">
                {t("home.vr-apps-description-1")}
              </p>
              <p className="section-services-tec-info-desc-text">
                {t("home.vr-apps-description-2")}
              </p>
              <a
                href="/services#AppsVR"
                className="section-services-tec-desc-link"
              >
                {t("common.view-more")}
              </a>
            </div>
          </div>
        )}
        {activeSerTec === ServTecCollection.V3D && (
          <div className="section-services-tec-info" data-aos="flip-right">
            <img
              src={ImgWarrior360}
              className="section-services-tec-info-img"
            />
            <div className="section-services-tec-info-desc">
              <p className="section-services-tec-info-desc-text">
                {t("home.360-videos-description")}
              </p>
              <a
                href="/services#Apps360"
                className="section-services-tec-desc-link"
              >
                {t("common.view-more")}
              </a>
            </div>
          </div>
        )}
      </div>
      <div className="green-separator"></div>
      <div className="section-services-adv">
        <h2 className="section-services-adv-title" data-aos="fade-up">
          {t("home.audiovisual-services")}
        </h2>
        <div className="section-services-adv-container">
          <div className="section-services-adv-opt" data-aos="fade-left">
            <p
              className={`section-services-adv-opt-title ${
                activeSerAdv === ServAdvCollection.M3D ? "active" : ""
              }`}
              onClick={() => setactiveAdvTec(ServAdvCollection.M3D)}
            >
              {t("home.3D-modeling")}
            </p>
            <p
              className={`section-services-adv-opt-title ${
                activeSerAdv === ServAdvCollection.ED ? "active" : ""
              }`}
              onClick={() => setactiveAdvTec(ServAdvCollection.ED)}
            >
              {t("home.digital-sculpture")}
            </p>
            <p
              className={`section-services-adv-opt-title ${
                activeSerAdv === ServAdvCollection.TEXTT ? "active" : ""
              }`}
              onClick={() => setactiveAdvTec(ServAdvCollection.TEXTT)}
            >
              {t("home.texturing")}
            </p>
            <p
              className={`section-services-adv-opt-title ${
                activeSerAdv === ServAdvCollection.RIGS ? "active" : ""
              }`}
              onClick={() => setactiveAdvTec(ServAdvCollection.RIGS)}
            >
              {t("home.rig-2d3d")}
            </p>
            <p
              className={`section-services-adv-opt-title ${
                activeSerAdv === ServAdvCollection.ANIM ? "active" : ""
              }`}
              onClick={() => setactiveAdvTec(ServAdvCollection.ANIM)}
            >
              {t("home.animations")}
            </p>
            <p
              className={`section-services-adv-opt-title ${
                activeSerAdv === ServAdvCollection.MG ? "active" : ""
              }`}
              onClick={() => setactiveAdvTec(ServAdvCollection.MG)}
            >
              {t("home.motion-graphics")}
            </p>
            <p
              className={`section-services-adv-opt-title ${
                activeSerAdv === ServAdvCollection.CVFX ? "active" : ""
              }`}
              onClick={() => setactiveAdvTec(ServAdvCollection.CVFX)}
            >
              {t("home.compositing")}
            </p>
          </div>
          {activeSerAdv === ServAdvCollection.M3D && (
            <div className="section-services-adv-info" data-aos="fade-right">
              <div className="section-services-adv-info-desc">
                <p className="section-services-adv-info-desc-text">
                  {t("home.3D-modeling-description-1")}
                </p>
                <p className="section-services-adv-info-desc-text">
                  {t("home.3D-modeling-description-2")}
                </p>
                <a
                  href="/services#ServiceM3D"
                  className="section-services-adv-info-desc-link"
                >
                  {t("common.view-more")}
                </a>
              </div>
              <img
                src={ImgMedusaMod}
                className="section-services-adv-info-img"
              />
            </div>
          )}
          {activeSerAdv === ServAdvCollection.ED && (
            <div className="section-services-adv-info" data-aos="fade-right">
              <div className="section-services-adv-info-desc">
                <p className="section-services-adv-info-desc-text">
                  {t("home.digital-sculpture-description")}
                </p>
                <a
                  href="/services#ServiceED"
                  className="section-services-adv-info-desc-link"
                >
                  {t("common.view-more")}
                </a>
              </div>
              <img
                src={ImgWarriorEsc}
                className="section-services-adv-info-img"
              />
            </div>
          )}
          {activeSerAdv === ServAdvCollection.TEXTT && (
            <div className="section-services-adv-info" data-aos="fade-right">
              <div className="section-services-adv-info-desc">
                <p className="section-services-adv-info-desc-text">
                  {t("home.texturing-description")}
                </p>
                <a
                  href="/services#ServiceTextt"
                  className="section-services-adv-info-desc-link"
                >
                  {t("common.view-more")}
                </a>
              </div>
              <img
                src={ImgMaskText}
                className="section-services-adv-info-img"
              />
            </div>
          )}
          {activeSerAdv === ServAdvCollection.RIGS && (
            <div className="section-services-adv-info" data-aos="fade-right">
              <div className="section-services-adv-info-desc">
                <p className="section-services-adv-info-desc-text">
                  {t("home.rig-2d3d-description")}
                </p>
                <a
                  href="/services#ServiceRigs"
                  className="section-services-adv-info-desc-link"
                >
                  {t("common.view-more")}
                </a>
              </div>
              <img src={ImgDinoRig} className="section-services-adv-info-img" />
            </div>
          )}
          {activeSerAdv === ServAdvCollection.ANIM && (
            <div className="section-services-adv-info" data-aos="fade-right">
              <div className="section-services-adv-info-desc">
                <p className="section-services-adv-info-desc-text">
                  {t("home.animations-description")}
                </p>
                <a
                  href="/services#ServiceAnim"
                  className="section-services-adv-info-desc-link"
                >
                  {t("common.view-more")}
                </a>
              </div>
              <img src={ImgWarrior} className="section-services-adv-info-img" />
            </div>
          )}
          {activeSerAdv === ServAdvCollection.MG && (
            <div className="section-services-adv-info" data-aos="fade-right">
              <div className="section-services-adv-info-desc">
                <p className="section-services-adv-info-desc-text">
                  {t("home.motion-graphics-description-1")}
                </p>
                <p className="section-services-adv-info-desc-text">
                  {t("home.motion-graphics-description-2")}
                </p>
                <a
                  href="/services#ServiceMG"
                  className="section-services-adv-info-desc-link"
                >
                  {t("common.view-more")}
                </a>
              </div>
              <img
                src={ImgMotionGrap}
                className="section-services-adv-info-img"
              />
            </div>
          )}
          {activeSerAdv === ServAdvCollection.CVFX && (
            <div className="section-services-adv-info" data-aos="fade-right">
              <div className="section-services-adv-info-desc">
                <p className="section-services-adv-info-desc-text">
                  {t("home.compositing-description-1")}
                </p>
                <p className="section-services-adv-info-desc-text">
                  {t("home.compositing-description-2")}
                </p>
                <a
                  href="/services#ServiceCVFX"
                  className="section-services-adv-info-desc-link"
                >
                  {t("common.view-more")}
                </a>
              </div>
              <img src={ImgVFX} className="section-services-adv-info-img" />
            </div>
          )}
        </div>
      </div>
      <div className="purple-separator"></div>
      <div className="section-works">
        <h2 className="section-works-title" data-aos="zoom-out-down">
          {t("home.ip-title")}
        </h2>
        <div className="section-works-opt">
          <div
            className={`section-works-opt-element ${
              activeWork === WorksCollection.W1 ? "active" : ""
            }`}
            onClick={() => setactiveWork(WorksCollection.W1)}
            data-aos="fade-up"
          >
            <img
              src={ImgSapiensDino}
              className="section-works-opt-element-img"
            />
            <p className="section-works-opt-element-title">{t("home.ip-sapiensaurus")}</p>
          </div>
          <div
            className={`section-works-opt-element ${
              activeWork === WorksCollection.W2 ? "active" : ""
            }`}
            onClick={() => setactiveWork(WorksCollection.W2)}
            data-aos="fade-up"
          >
            <img
              src={ImgQuantumQuarck}
              className="section-works-opt-element-img"
            />
            <p className="section-works-opt-element-title">{t("home.ip-quantum")}</p>
          </div>
          <div
            className={`section-works-opt-element ${
              activeWork === WorksCollection.W3 ? "active" : ""
            }`}
            onClick={() => setactiveWork(WorksCollection.W3)}
            data-aos="fade-up"
          >
            <img
              src={ImgYoutubeChannel}
              className="section-works-opt-element-img"
            />
            <p className="section-works-opt-element-title">
              {t("home.ip-youtube")}
            </p>
          </div>
          <div
            className={`section-works-opt-element ${
              activeWork === WorksCollection.W4 ? "active" : ""
            }`}
            onClick={() => setactiveWork(WorksCollection.W4)}
            data-aos="fade-up"
          >
            <img src={ImgGalery} className="section-works-opt-element-img" />
            <p className="section-works-opt-element-title">{t("home.ip-gallery")}</p>
          </div>
        </div>
        {activeWork === WorksCollection.W1 && (
          <div className="section-works-info" data-aos="fade-down">
            <img src={ImgSapiensDino} className="section-works-info-img" />
            <div className="section-works-info-desc">
              <p className="section-works-info-desc-text">
                {t("home.ip-sapiensaurus-description")}
              </p>
              <a href="/projects#IpW1" className="section-works-desc-link">
                {t("common.view-more")}
              </a>
            </div>
          </div>
        )}
        {activeWork === WorksCollection.W2 && (
          <div className="section-works-info" data-aos="fade-down">
            <img src={ImgQuantumQuarck} className="section-works-info-img" />
            <div className="section-works-info-desc">
              <p className="section-works-info-desc-text">
                {t("home.ip-quantum-description")}
              </p>
              <a href="/projects#IpW2" className="section-works-desc-link">
                {t("common.view-more")}
              </a>
            </div>
          </div>
        )}
        {activeWork === WorksCollection.W3 && (
          <div className="section-works-info" data-aos="fade-down">
            <img src={ImgYoutubeChannel} className="section-works-info-img" />
            <div className="section-works-info-desc">
              <p className="section-works-info-desc-text">
                {t("home.ip-youtube-description")}
              </p>
              <a href="/projects#IpW3" className="section-works-desc-link">
                {t("common.view-more")}
              </a>
            </div>
          </div>
        )}
        {activeWork === WorksCollection.W4 && (
          <div className="section-works-info" data-aos="fade-down">
            <img src={ImgGalery} className="section-works-info-img" />
            <div className="section-works-info-desc">
              <p className="section-works-info-desc-text">
                {t("home.ip-gallery-description-1")}
              </p>
              <p className="section-works-info-desc-text">
                {t("home.ip-gallery-description-2")}
              </p>
              <a href="/projects#IpW4" className="section-works-desc-link">
                {t("common.view-more")}
              </a>
            </div>
          </div>
        )}
      </div>
      <div className="white-separator"></div>
      <div className="section-collaborators">
        <h2 className="section-collaborators-title" data-aos="fade-up">
          {t("home.collaborators-tile")}
        </h2>
        <div className="section-collaborators-row">
          <img
            src={LogoAura}
            className="section-collaborators-row-img"
            data-aos="zoom-in-up"
          />
          <img
            src={LogoCaos}
            className="section-collaborators-row-img"
            data-aos="zoom-in-up"
          />
          <img
            src={LogoDeep}
            className="section-collaborators-row-img"
            data-aos="zoom-in-up"
          />
          <img
            src={LogoEregebe}
            className="section-collaborators-row-img"
            data-aos="zoom-in-up"
          />
          <img
            src={LogoIlimita}
            className="section-collaborators-row-img"
            data-aos="zoom-in-up"
          />
          <img
            src={LogoPlatypus}
            className="section-collaborators-row-img"
            data-aos="zoom-in-up"
          />
          <img
            src={LogoCebolla}
            className="section-collaborators-row-img"
            data-aos="zoom-in-up"
          />
          <img
            src={LogoHeski}
            className="section-collaborators-row-img"
            data-aos="zoom-in-up"
          />
          <img
            src={LogoDX}
            className="section-collaborators-row-img"
            data-aos="zoom-in-up"
          />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Home;
